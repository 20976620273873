<template>
    <v-app-bar app flat class="toolbar-shadow" color="white">
        <v-app-bar-nav-icon class="hidden-md-and-up"
                            @click="toggleDrawer"
                            :prominent="$vuetify.breakpoint.mdAndUp" />

        <v-container class="mx-auto py-0">
            <v-row align="center" :class="$vuetify.breakpoint.mdAndUp ? '' : 'flex-row'">
                <v-col cols="auto" justify="start">
                    <v-img src="https://bonsai.demoing.co.za/assets/images/logo.png" width="150" contain />
                </v-col>
                <v-spacer></v-spacer>
                <v-col cols="auto" justify="center" v-if="$vuetify.breakpoint.mdAndUp">
                    <menu-button icon="gjduyosq" to="/sign-in">
                        Sign Up
                    </menu-button>
                    <menu-button icon="usjxhgom" to="/sign-up">
                        Sign In
                    </menu-button>
                </v-col>
            </v-row>
        </v-container>
        <template v-slot:extension v-if="$vuetify.breakpoint.mdAndUp">
            <v-container class="mx-auto py-0">
                <v-row align="center">
                    <v-col cols="auto">
                        <menu-button icon="gmzxduhd" to="/">
                            Home
                        </menu-button>
                        <dropdown-menu-button icon="ibnvinkb">
                            Services
                            <template v-slot:menu-content>
                                <menu-button icon="imamsnbq" to="/individual">
                                    Individual Services
                                </menu-button>
                                <menu-button icon="akbjoiow" to="/business">
                                    Business Services
                                </menu-button>
                            </template>
                        </dropdown-menu-button>
                        <menu-button icon="lwafhxqu" to="/products">
                            Products
                        </menu-button>
                        <menu-button icon="uiqbfxbp" to="/get-quote">
                            Get Quote
                        </menu-button>
                        <menu-button icon="sfkskmhu" to="/about">
                            About Us
                        </menu-button>
                    </v-col>
                    <v-spacer />
                    <v-col cols="auto">
                        <menu-button icon="tdxypxgp" to="/video">
                            Watch Video
                        </menu-button>
                    </v-col>
                </v-row>
            </v-container>
        </template>
    </v-app-bar>
</template>
<script>
    // Utilities
    import { mapMutations } from "vuex";
    import MenuButton from './components/toolbar/menu-button.vue'
    import DropdownMenuButton from './components/toolbar/dropdown-menu-button.vue'

    export default {
        name: "toolbar",
        components: {
            MenuButton,
            DropdownMenuButton
        },
        data: function () {
            return {
                showMenu: false
            }
        },
        computed: {},

        methods: {
            ...mapMutations(["toggleDrawer"]),
            hoverState: function (state) {
                this.showMenu = state
            }
        },
    };
</script>