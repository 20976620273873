<template>
  <v-app>
    <v-system-bar></v-system-bar>
    <toolbar />
    <drawer v-if="!$vuetify.breakpoint.mdAndUp" />
    
    
    <v-main>
      <v-container class="mb-10">
          <!--<v-row justify="center">
              <v-col sm="12" md="12" lg="11" xl="10">-->
                  <v-scroll-x-transition leave-absolute hide-on-leave>
                      <router-view />
                  </v-scroll-x-transition>
              <!--</v-col>
          </v-row>-->
      </v-container>
    </v-main>
    <footbar />
  </v-app>
</template>

<script>
import Toolbar from "./toolbar.vue";
import Drawer from "./drawer.vue";
import Footbar from "./footbar.vue"
export default {
  name: "App",
  components: {
    Toolbar,
    Drawer,
    Footbar
  },
  data: () => ({
    //
  }),
};
</script>
