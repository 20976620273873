

<template>
    <v-hover v-slot="{ hover }">
        <v-list-item :to="to">
            <v-list-item-avatar tile>
                <lord-icon v:bind="$attrs" :src="`https://cdn.lordicon.com/${icon}.json`"
                           :trigger="hover ? 'loop' : ''"
                           :colors="`primary:#121331,secondary:${$vuetify.theme.themes.light.primary}`"
                           stroke="100"
                           style="width:50px;height:50px">
                </lord-icon>
            </v-list-item-avatar>
            <v-list-item-content>
                <v-list-item-title>
                    <span class="v-menu-button-text">
                        <slot :class="hover ? 'primary--text' : 'grey--text'"></slot>
                    </span>
                </v-list-item-title>
            </v-list-item-content>
        </v-list-item>
    </v-hover>
</template>
<script>
    export default {
        name: 'drawer-menu-item',
        components: {},
        props: {
            icon: String,
            to: String
        },
        data: () => {
            return {
            };
        },
    };
</script>