import Vue from 'vue'
import App from './app.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import './assets/style.css'
import 'remixicon/fonts/remixicon.css'
Vue.config.productionTip = false

import LottieAnimation from "lottie-vuejs/src/LottieAnimation.vue"; // import lottie-vuejs

Vue.use(LottieAnimation); // add lottie-animation to your global scope
Vue.prototype.$touchable = (('ontouchstart' in window) ||
    (navigator.maxTouchPoints > 0) ||
    (navigator.msMaxTouchPoints > 0));

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')


