<template>
  <v-footer dark padless>
    <v-container>
      <v-row class="pt-5">
        <v-col cols="12" md="6">
          <img src="https://www.tokencapital.tech/images/logof.png" />
          <p class="pt-3 body-2">
            In publishing and graphic design, lorem ipsum is placeholder text
            (filler text) commonly used to demonstrate the graphics elements of
            a document or visual presentation, such as font, typography, and
            layout. The lorem ipsum text is typically a section of a Latin text
            by Cicero with words altered, added and removed that make it
            nonsensical in meaning and not proper Latin.
          </p>
        </v-col>
        <v-col cols="12" md="12" lg="2">
          <h4>Navigation</h4>
          <v-divider />
          <v-btn plain class="normal-caps" to="/">home</v-btn><br/>
          <v-btn plain class="normal-caps">Individual Services</v-btn><br/>
          <v-btn plain class="normal-caps">Business Services</v-btn><br/>
          <v-btn plain class="normal-caps">About Us</v-btn>
        </v-col>
        <v-col cols="12" md="12" lg="2">
          <h4>Account</h4>

          <v-divider />
          <v-btn plain class="normal-caps">Sign In</v-btn><br/>
          <v-btn plain class="normal-caps">Register</v-btn><br/>
          <v-btn plain class="normal-caps">Support</v-btn>
        </v-col>
        <v-col cols="12" md="12" lg="2">
          <h4>Legal</h4>
          <v-divider />
          <v-btn plain class="normal-caps">Terms &amp; Conditions</v-btn><br/>
          <v-btn plain class="normal-caps">Privacy Policy</v-btn><br/>
          <v-btn plain class="normal-caps">Complaints Process</v-btn><br/>
          <v-btn plain class="normal-caps">FAIS Disclosure</v-btn>
        </v-col>
      </v-row>
    </v-container>
  </v-footer>
</template>

<script>
export default {
  name: "footbar",
};
</script>

<style>
</style>