import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'Home',
        component: () => import('../views/home.vue')
    },
    {
        path: '/individual',
        name: 'Individual',
        component: () => import('../views/individual.vue')
    },
    {
        path: '/business',
        name: 'Business',
        component: () => import('../views/business.vue')
    },
    {
        path: '/products',
        name: 'Products',
        component: () => import('../views/products.vue')
    },
    {
        path: '/about',
        name: 'About',
        component: () => import('../views/about.vue')
    },
    {
        path: '/get-quote',
        name: 'GetQuote',
        component: () => import('../views/get-quote.vue'),
        props: route => ({ item: route.query.item }),
    },

]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router
