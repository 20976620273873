<template>
    <v-menu open-on-hover
            bottom
            :nudge-left="20"
            transition="slide-x-transition"
            offset-y>
        <template v-slot:activator="{ on, attrs }">
            <div class="v-menu-button mr-4" v-on="on" v-bind="attrs">
                <v-row align="center" no-gutters>
                    <v-col cols="auto" class="pr-1">
                        <lord-icon :src="`https://cdn.lordicon.com/${icon}.json`"
                                   :colors="`primary:#121331,secondary:${$vuetify.theme.themes.light.primary}`"
                                   stroke="100"
                                   style="width:24px;height:24px">
                        </lord-icon>
                    </v-col>
                    <v-col>
                        <span class="v-menu-button-text">
                            <slot :class="on ? 'primary--text' : 'grey--text'"></slot>
                        </span>
                    </v-col>
                </v-row>
            </div>
        </template>
        <div class="pa-5">
            <v-card class="card-shadow-hover ">
                <v-card-text>
                    <div class="d-flex flex-column drop-down-menu-button">
                        <slot name="menu-content">skrrrt</slot>
                    </div>
                </v-card-text>
            </v-card>
        </div>
    </v-menu>
    <!--</v-hover>-->
</template>
<script>
    export default {
        components: {},
        props: {
            icon: String,
            to: String
        },
        data: () => {
            return {
            };
        },
    };
</script>