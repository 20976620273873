<template>
    <v-navigation-drawer v-model="drawer" app>
        <v-container class="mt-5 mb-5">
            <v-row justify="center">
                <v-col cols="auto">
                    <v-img src="https://bonsai.demoing.co.za/assets/images/logo.png" width="150" contain />
                </v-col>
            </v-row>
        </v-container>
        <v-divider class="mx-4" />
        <v-list nav dense>
            <drawer-menu-item icon="gmzxduhd" to="/">Home</drawer-menu-item>

            <drawer-menu-item icon="imamsnbq" to="/business">
                Individual Services
            </drawer-menu-item>
            <drawer-menu-item icon="akbjoiow" to="/individual">
                Business Services
            </drawer-menu-item>
            <drawer-menu-item icon="uiqbfxbp" to="/about">
                Get Quote
            </drawer-menu-item>
            <drawer-menu-item icon="lwafhxqu" to="/products">
                Products
            </drawer-menu-item>
            <drawer-menu-item icon="sfkskmhu" to="/about">
                About Us
            </drawer-menu-item>
            <!--<v-list-item to="/">
            <v-list-item-avatar><v-icon>mdi-home</v-icon></v-list-item-avatar>
            <v-list-item-content>
                <v-list-item-title>Home</v-list-item-title>
            </v-list-item-content>
        </v-list-item>
    <v-list-item to="/individual">
        <v-list-item-avatar>  <v-icon>mdi-account</v-icon></v-list-item-avatar>
        <v-list-item-content>
            <v-list-item-title>Individual Service</v-list-item-title>
        </v-list-item-content>
    </v-list-item>
    <v-list-item to="/business">
        <v-list-item-avatar> <v-icon>mdi-account-tie</v-icon></v-list-item-avatar>
        <v-list-item-content>
            <v-list-item-title>Business Service</v-list-item-title>
        </v-list-item-content>
    </v-list-item>
    <v-list-item to="/about">
        <v-list-item-avatar> <v-icon>mdi-account-group</v-icon></v-list-item-avatar>
        <v-list-item-content>
            <v-list-item-title>About Us</v-list-item-title>
        </v-list-item-content>
    </v-list-item>-->
            <v-divider>

            </v-divider>
            <drawer-menu-item icon="tdxypxgp" to="/video">
                Watch Video
            </drawer-menu-item>
            <v-divider>

            </v-divider>
            <drawer-menu-item icon="gjduyosq" to="/sign-in">
                Sign Up
            </drawer-menu-item>
            <drawer-menu-item icon="usjxhgom" to="/sign-up">
                Sign In
            </drawer-menu-item>
            <!--<v-list-item to="/about">
        <v-list-item-avatar> <v-icon>mdi-key-plus</v-icon></v-list-item-avatar>
        <v-list-item-content>
            <v-list-item-title>Sign Up</v-list-item-title>
        </v-list-item-content>
    </v-list-item>
    <v-list-item to="/about">
        <v-list-item-avatar> <v-icon>mdi-key-plus</v-icon></v-list-item-avatar>
        <v-list-item-content>
            <v-list-item-title>Sign in</v-list-item-title>
        </v-list-item-content>
    </v-list-item>-->
        </v-list>
    </v-navigation-drawer>
</template>

<script>
    // Utilities
    import { mapMutations } from "vuex";
    import DrawerMenuItem from './components/toolbar/drawer-menu-item.vue'
    export default {
        name: "drawer",
        components: {
            DrawerMenuItem
        },
        computed: {
            drawer: {
                get() {
                    return this.$store.state.drawer;
                },
                set(val) {
                    this.setDrawer(val);
                },
            },
        },

        methods: {
            ...mapMutations(["setDrawer"]),
        },
    };
</script>
