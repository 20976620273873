<template>
    <v-hover v-slot="{ hover }">
        <router-link :to="to" tag="div" class="v-menu-button mr-4" >
            <!-- <v-sheet  onclick="window.location='/products';">-->
            <v-row align="center" no-gutters>
                <v-col cols="auto" class="pr-1">
                    <lord-icon :src="`https://cdn.lordicon.com/${icon}.json`"
                               :trigger="hover ? 'loop' : ''"
                               :colors="`primary:#121331,secondary:${$vuetify.theme.themes.light.primary}`"
                               stroke="100"
                               style="width:24px;height:24px">
                    </lord-icon>
                </v-col>
                <v-col>
                    <span class="v-menu-button-text">
                        <slot :class="hover ? 'primary--text' : 'grey--text'"></slot>
                    </span>
                </v-col>
            </v-row>
            <!--</v-sheet>-->
        </router-link>
    </v-hover>
</template>
<script>
    export default {
        name: 'menu-button',
        components: {},
        props: {
            icon: String,
            to: String
        },
        data: () => {
            return {
            };
        },
    };
</script>